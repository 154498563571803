.scrollIndicatorsHidden {
    scrollbar-width: none;                       /* Firefox */
}

.scrollIndicatorsHidden::-webkit-scrollbar {
    display: none;                               /* Safari, Chrome */
}

button.plain:active {
    opacity: 0.9;
}

button:not(.plain):active {
    opacity: 0.5;
}

a {
    color: rgb(0, 150, 255);
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 0.1s ease-out;
}

a.plain:active {
    opacity: 0.9;
}

a:not(.plain):active {
    opacity: 0.5;
}

p {
    overflow-wrap: anywhere;
}

p:first-of-type {
    margin-top: 0;
}

p:last-of-type {
    margin-bottom: 0;
}

@media not (hover: hover) {
    .Picker:has(:focus) {
        opacity: 0.5;
    }
}

@media (hover: hover) {
    .Picker:hover {
        opacity: 0.5;
    }
    button.plain:hover {
        opacity: 0.9;
    }
    a.plain:hover {
        opacity: 0.9;
    }
    button:not(.plain):hover {
        opacity: 0.5;
    }
    a:not(.plain):hover {
        opacity: 0.5;
    }
}