.ios-spinner {
    width: 32px;
    height: 32px;
    position: relative;
}

.ios-spinner div {
    box-sizing: border-box;
    position: absolute;
    width: 32px;
    height: 32px;
    border: 4px solid;
    border-radius: 50%;
    animation: ios-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(199, 199, 204) transparent transparent transparent;
}

.ios-spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.ios-spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.ios-spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes ios-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
